import { Button, Stack } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Welcome from "../components/Welcome";
import CustomFormProvider from "../components/CustomFormProvider";
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import WelcomeV2 from "../components/WelcomeV2";

type Props = {
  userId?: string;
};

export const MainContent = ({ userId }: Props) => {
  const [isFormVisible, setIsFormVisible] = useState(userId ? true : false);

  // variants for form entrance/exit animations
  const formVariants = {
    show: {
      opacity: 1,
      clipPath: `circle(${1000 * 2 + 200}px at center center)`,
      transition: { duration: 0.5, delay: 0.25 },
    },
    hide: {
      opacity: 0,
    },
  };

  return (
    <Stack sx={{ mb: 5, p: 2, pt: 0, display: "flex", overflow: "hidden" }}>
      {/* welcome card */}
      <AnimatePresence mode="wait">
        {!isFormVisible && 
          <Welcome />
          // <WelcomeV2 />
        }
      </AnimatePresence>

      {/* button toggles between welcome card and sign up form */}
      {/* <Button
        color="secondary"
        variant={!isFormVisible ? "contained" : "text"}
        onClick={() => setIsFormVisible((prev) => !prev)}
        sx={
          !isFormVisible
            ? { mx: "auto", my: 2, px: 5, maxWidth: "300px" }
            : {
                borderRadius: 100,
                my: 0,
                mx: "auto",
                px: 0,
              }
        }
      >
        {!isFormVisible ? "Sign me up!" : <HelpOutlineIcon />}
      </Button> */}

      {/* form card and animations */}
      <motion.div
        animate={isFormVisible ? "show" : "hide"}
        variants={formVariants}
      >
        <CustomFormProvider show={isFormVisible} userId={userId} />
      </motion.div>
    </Stack>
  );
};

export default MainContent;
