import {ClickAwayListener, Stack, Tooltip, Typography} from "@mui/material";
import {motion} from "framer-motion";
import H1 from "./H1";
import theme from "../styles/theme";
import {useState} from "react";

/**
 *
 * @returns welcome card
 */
const Welcome = () => {

    const [copied, setCopied] = useState(false);
    const email = "contact@localbeat.org";

    const copyToClipboard = () => {
        navigator.clipboard.writeText(email);
        setCopied(true);
        setTimeout(() => setCopied(false), 1500); // Hide tooltip after 1.5s
    };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 1.25,
          delay: 0.1,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.25,
        },
      }}
    >
        <Stack
            spacing={2}
            sx={{maxWidth: "600px", m: "0 auto", p: "0 3rem"}}
            key={"welcomeStack"}
        >
            <Typography variant="h4" align="center">
                Thanks for stopping by
            </Typography>
            <H1 title="localbeat" allCaps/>
            <ClickAwayListener onClickAway={() => setCopied(false)}>
                <Tooltip
                    open={copied}
                    title="Copied!"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener>
                    <Typography>
                        We're hard at work building something exciting. <br/>
                        Be one of the first to know when we go live! <br/>
                        Drop us an email at{" "}
                        <span
                            onClick={copyToClipboard}
                            title="Click to copy"
                            style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                textDecorationColor: theme.palette.secondary.main,
                                textDecorationThickness: ".1rem",
                            }}
                        >
                            {email}
                        </span>
                        , and we’ll add you to our update list. <br/>
                        We appreciate your support—stay tuned.
                    </Typography>
                </Tooltip>
            </ClickAwayListener>
        {/* <Typography>
          Introducing{" "}
          <span
            style={{
              textDecoration: "underline",
              textDecorationColor: theme.palette.secondary.main,
              textDecorationThickness: ".1rem",
            }}
          >
            LOCALBEAT
          </span>
          ! Your personalized passport to Colorado's most exciting live events.
        </Typography>
        <Typography>
          Forget endless scrolling. Forget website trolling. Tell us what makes
          you tick (music, arts, food, comedy, film, theatre, etc.), and we'll
          curate a weekly feed of experiences that make you say "WOW!".
        </Typography>
        <Typography>
          Skip the FOMO. Be the first to know about exclusive events and hidden
          gems before they sell out.
        </Typography>
        <Typography>Ready to ditch the ordinary?</Typography>
        <Typography>
          Sign up for our FREE newsletter below and unlock a world of
          unforgettable experiences! ⬇️
        </Typography>
        <Typography variant="body2" textAlign={"center"}>
          (P.S. We won't spam you - just weekly doses of awesome!)
        </Typography> */}
      </Stack>
    </motion.div>
  );
};

export default Welcome;
