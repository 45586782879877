import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ChipSelect from "./ChipSelect";
import { Controller, SubmitHandler, useFormContext } from "react-hook-form";
import { Schema } from "../types/formSchema";
import GenericAutocomplete from "./GenericAutocomplete";
import { useCampaigns, useCategories, useUser } from "../services/queries";
import { normalizeData, useCreateUser } from "../services/mutations";
import { Option } from "../types/option";
import {
  alphabeticalSort,
  categoryFilter,
  formatFetchedUserData,
  genreFilter,
} from "../services/utils";

type Props = {
  // determines whether form is rendered
  show: boolean;
  // used to display current data if user exists
  userId?: string;
};

/**
 *
 * @param Props
 * @returns sign up/manage form for the newsletter and music prefs
 */
const Form = ({ show, userId }: Props) => {
  const {
    register,
    formState: { errors },
    // watch, // used for logging current form values
    handleSubmit,
    getValues,
    reset,
    control,
  } = useFormContext<Schema>();

  const [genres, setGenres] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [campaigns, setCampaigns] = useState<Option[]>([{ id: "", label: "" }]);
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const categoryData = useCategories();
  const campaignData = useCampaigns();
  const userData = useUser(userId);

  const createUserPost = useCreateUser();

  /**
   * handles form submission
   * @param data form data
   */
  const submitForm: SubmitHandler<Schema> = (data) => {
    // helpful logger for submitted data
    // console.log(normalizeData(data));
    setIsFormSubmitted((prev) => !prev);
    setLoadingState();
    createUserPost.mutate(data);
  };

  /**
   * creates a default loading timer of 1 sec
   * regardless of mutation state, the loading icon displays for 1 sec after form submission
   */
  const setLoadingState = () => {
    setLoading((prev) => !prev);
    setTimeout(() => setLoading((prev) => !prev), 1000);
  };

  /**
   * sets genres and category options when data fetches successfully
   */
  useEffect(() => {
    if (categoryData.data?.data) {
      // sorts each alphabetically and filters based on display rules
      const sortedCategories = alphabeticalSort(
        categoryData.data.data.filter(categoryFilter),
        "categoryCode"
      );
      setCategories(sortedCategories);

      const sortedGenres = alphabeticalSort(
        categoryData.data.data.filter(genreFilter),
        "categoryCode"
      );
      setGenres(sortedGenres);
    }
  }, [categoryData.data]);

  /**
   * sets campaign options
   */
  useEffect(() => {
    if (campaignData.data?.data) {
      let options: Option[] = [];
      campaignData.data.data.forEach(
        (element: { campaignId: string; name: string }) => {
          options.push({
            id: element.campaignId,
            label: element.name,
          });
        }
      );
      setCampaigns(options);
    }
  }, [campaignData.data]);

  /**
   * updates form with existing info if user already exists
   */
  useEffect(() => {
    if (userData.data) {
      reset(formatFetchedUserData(userData.data));
    }
  }, [reset, userData.data]);

  /**
   * useful debugging tool to monitor current values of form
   * uncomment to use
   */
  // useEffect(() => {
  //   const sub = watch((values, formState) => {
  //     console.log("current form values", values);
  //   });

  //   return () => sub.unsubscribe();
  // }, [watch]);

  return (
    <>
      {show && (
        <Container
          maxWidth="md"
          sx={{
            mx: "auto",
            mb: 15,
            textAlign: "center",
            color: "background.main",
            backgroundColor: "transparent",
            overflow: "scroll",
          }}
        >
          {/* form pre-submit */}
          {!isFormSubmitted && (
            <>
              <FormHelperText sx={{ mb: 3, textAlign: "center" }}>
                We are currently curating events under the following domains.
                <br /> Check back soon for more options.
              </FormHelperText>
              {/* {manage message} */}
              {userId && (
                <>
                  <Typography variant="h5">
                    Welcome back, {getValues().name.split(" ")[0]}!
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Manage your selections below
                  </Typography>
                </>
              )}
              <Typography
                variant="overline"
                display="block"
                gutterBottom
                color="text.secondary"
              >
                tell us what you're interested in seeing
              </Typography>
              {categories && (
                <ChipSelect<Schema> name="categories" options={categories} />
              )}
              <Divider sx={{ m: "1em 15%" }} />
              <Typography
                variant="overline"
                display="block"
                gutterBottom
                color="text.secondary"
              >
                which genres do you like?
              </Typography>
              {genres && <ChipSelect<Schema> name="genres" options={genres} />}
              <Stack
                sx={{
                  mx: "auto",
                  my: 3,
                  maxWidth: "450px",
                  gap: 2.75,
                }}
              >
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value }, fieldState: { error } }) => (
                    <TextField
                      required
                      label="Name"
                      id="name"
                      variant="filled"
                      size="small"
                      value={value}
                      {...register("name")}
                      error={!!errors.name}
                      helperText={errors.name?.message}
                    />
                  )}
                />
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value }, fieldState: { error } }) => (
                    <TextField
                      required
                      label="Email"
                      variant="filled"
                      size="small"
                      id="email"
                      value={value}
                      {...register("email")}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
                <GenericAutocomplete<Schema>
                  name="campaigns"
                  label="News Frequency"
                  options={campaigns}
                />
                {/* if on manage page, display unsub message */}
                {userId && (
                  <FormHelperText sx={{ mt: -1.5 }} error>
                    ** If you wish to unsubscribe, uncheck all options. **
                  </FormHelperText>
                )}
                <FormHelperText sx={{ mt: -1.5 }}>
                  How often would you like to receive your personalized
                  newsletter?
                  <br />
                  Every night, just once for the weekend, or both?
                  <br />
                  You can always change this later.
                </FormHelperText>
                <Controller
                  name="zip"
                  control={control}
                  render={({ field: { value }, fieldState: { error } }) => (
                    <TextField
                      required
                      label="Zip Code"
                      variant="filled"
                      size="small"
                      value={value}
                      {...register("zip")}
                      error={!!errors.zip}
                      helperText={errors.zip?.message}
                    />
                  )}
                />
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ mx: 7 }}
                  onClick={handleSubmit(submitForm)}
                >
                  Submit
                </Button>
              </Stack>
            </>
          )}
          {/* loading component */}
          {(createUserPost.isPending || loading) && (
            <Box
              sx={{
                m: "3rem auto",
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {/* Success message */}
          {createUserPost.isSuccess && !loading && (
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              color="text.secondary"
              sx={{ mt: 2, fontSize: 20 }}
            >
              Congratulations, <br />
              <Typography variant="h6">
                {getValues().name.split(" ")[0]} <br />
              </Typography>
              {/* conditional message if on manage page or initial sign up */}
              {userId
                ? `We've recorded your updates.`
                : `You're now in the loop!`}
              <br />
              <br />
              Check back soon for a better event search experience
            </Typography>
          )}
          {/* error message when form submission fails */}
          {createUserPost.isError && !loading && (
            <Typography
              variant="overline"
              display="block"
              gutterBottom
              color="text.secondary"
              sx={{ mt: 2, fontSize: 20 }}
            >
              We're sorry. Something went wrong.
              <br />
              Please,
              <br />
              <Button
                color="secondary"
                variant="contained"
                sx={{ mx: 7 }}
                onClick={() => window.location.reload()}
              >
                Try Again
              </Button>
            </Typography>
          )}
        </Container>
      )}
    </>
  );
};

export default Form;
